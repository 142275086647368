import React from 'react'
import Layout from '../components/Layout'
import {StaticImage} from 'gatsby-plugin-image'
import {Link} from 'gatsby'
import Seo from '../components/Seo'

const Services = () => {
    return (
        <Layout>
            <Seo  title="Diensten"/>
            <main className="page">
            <section className="about-page">
                <article>
                    <h5>Dit doen wij</h5>
                    <p>Te hoge AWS rekening? Wij helpen jouw AWS rekening te verlagen.  </p>
                    <p>Innoveren met AWS Cloud? Wij geven jouw innovatie een kickstart.</p>
                    <p>Migreren naar AWS, nieuw product, re-engineering? 
                        Wij maken jouw plannen werkend op AWS.</p>
                    <Link to="/contact" className="btn">
                        contact
                    </Link>
                </article>
                <StaticImage 
                src="../assets/images/servicesphoto.jpg" 
                alt="Person holding idea sign" 
                className = "about-img"
                placeholder ="blurred"/>
            </section>
            </main>
        </Layout>
    )
}

export default Services